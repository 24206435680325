import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default {
    atdwTiles : css({
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        //justifyContent: 'space-between'
        justifyContent: 'start'
    })
}