//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    atdwSearchOption : css({
         '& .form-group label' : {
             marginBottom : '0px'
         },
         '.form-control:focus' : {
            boxShadow : 'none'
         }
    }),
    search_btn : css(mq({
        marginTop: ['auto', '17px']
    }))
}