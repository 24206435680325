import { getFilename } from './url';
import { contains } from './array';
export function getImageSetPath(url, setId) {
  const info = getPathDetails(url)
  if (!info) return url;
  //console.log(url, info);
  return info.baseFolder + "/@" + setId + '/' + info.filename
}

export function getPathDetails(url, setIds) {
  if (!url) return null;
  const parts = url.split('/').slice(0, -1);
  if (parts.length <= 1) return { baseFolder: '/', setId: null, filename: getFilename(url) };

  const lastFolder = parts[parts.length - 1];
  const hasSet = setIds && setIds.length > 0 ? contains(setIds, x => x === lastFolder) : lastFolder.indexOf('@') === 0;

  return hasSet ?
    { baseFolder: parts.slice(0, -1).join('/'), setId: lastFolder, filename: getFilename(url) } :
    { baseFolder: parts.join('/'), setId: null, filename: getFilename(url) }
}