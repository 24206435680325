/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useState, useEffect } from 'react';
import style from './style';
import { AtdwTile } from '../tile/view';

export function AtdwSearchResult(props){
    
    //const [products, setProducts] = useState(props.atdwData || []);
    const atdwDatas = props.atdwData || [];

    return(
        <div className="atdwResult">
            <div css={style.atdwTiles} className="atdwTiles"> 
                { atdwDatas.map((item, index) => <AtdwTile {...item} key = {index}></AtdwTile> )}
                {/* { atdwDatas.map((item, index) => {
                    return (<AtdwTile {...item} key = {index}></AtdwTile>)
                })} */}
            </div>
        </div>
    )
}