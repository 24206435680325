import React from 'react';
import { Helmet } from 'react-helmet-async';

export function MetaTag({ data: seoData }) {
  //console.log(seoData)
  const data = Object.assign({}, {
    title: ''
  }, seoData)

  return (<>
    <Helmet>
      <title>{data.title}</title>
      {data.description && <meta name="description" content={data.description} /> }
      {data.keywords && <meta name="keywords" content={data.keywords} /> }
      {data.language && <meta name="language" content={data.language} /> }
      {data.country && <meta name="country" content={data.country} /> }
      {
        (data.faceBookDomainVerifi && data.faceBookDomainVerifi !== '') &&
        <meta name="facebook-domain-verification" content={data.faceBookDomainVerifi} />
      }
      <meta name="twitter:card" content={!data.heroImageUrl ? "summary" : "summary_large_image"} />
      <meta name="twitter:site" content={data.siteName} />
      <meta name="twitter:title" content={data.title} />
      {data.description && <meta name="twitter:description" content={data.description} /> }
      {data.heroImageUrl && <meta name="twitter:image" content={data.heroImageUrl} /> }

      <meta name="og:title" content={data.title} />
      <meta name="og:site_name" content={data.siteName} />
      <meta name="og:url" content={data.url} />
      {data.description && <meta name="og:description" content={data.description} /> }
      {data.heroImageUrl && <meta name="og:image" content={data.heroImageUrl} /> }


      <link rel="canonical" href={data.canonical || data.url} />

      {(data.alternates || []).map((x, index) => <link rel="alternate" key={index} hreflang={x.hreflang} href={x.href} />  )}

    </Helmet>
  </>)
}


// function MetaContent({ name, content }) {
//   console.log(name, content)
//   if (!content) return null;
//   return <Helmet><meta name={name} content={content}></meta></Helmet>
// }

