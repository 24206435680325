import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SearchResult } from 'site/searchResult/searchResult';
import { InstagramFeed } from 'components';
import { AppVersion } from 'components';

export function Code(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, cmsOption);

  const code = cmsUtils.payload(item, 'Code');
  const codeType = cmsUtils.payload(item, 'CodeType');
  let controls = null;
  if (codeType === 'Json') {
    const codeJson = JSON.parse(code);

    if (codeJson.control === 'search-results'){
      controls = <SearchResult />
    } else if(codeJson.control === 'app'){
      controls = <AppVersion />
    } else if (codeJson.control === 'instagram') {
      controls = <InstagramFeed />
    }
  }

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  if (cmsOption.isCmsEdit)
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
<pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
<b>Code Type: {codeType}</b>
{code} </pre>          
        </div>
    )
  else if (codeType === 'Text')
    return (
      <div className={cssClass} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: code }}></div>
    )
  else
    return (
      <div className={cssClass} id={item.anchorName}>{controls}</div>
    )
}