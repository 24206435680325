/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import style from './style';
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import env from '../../../env';
//import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";

function Portal({children}){
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function AtdwMap(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdwMap', item.cssClass || item.anchorName || '');
    const [mapSate, setMapSate ] = useState({
        currentFilterType :'ACCOMM',
        trigger:0,
        fetchAble:true,
        data : []
    });

    const el = useRef(null);
    const history = useHistory();
    const [openPopup, setOpenPopup] = useState(false);
    const [popupData, setPopupData] = useState();

    useEffect(()=>{
        if(mapSate.fetchAble === false){
            return;
        }
        const abortController = new AbortController();
        const signal = abortController.signal;
        let checkScript = true;
        const script = document.createElement('script');
        const fetchData = async () =>{
            const res = await fetch(env.apiBase + "/api/atdw/getproductsformap/" + mapSate.currentFilterType, {signal:signal});
            const data = await res.json();
            if(window['google'] === undefined){
                checkScript = false;
            }
            else{
                if(window['google'].maps === undefined){
                    checkScript = false;
                }
            }
            
            if(!checkScript){
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
                script.async = true;
                document.body.appendChild(script);
                setTimeout(() => {
                    setMapSate(pre=>{
                        let _new = {...pre};
                        _new.fetchAble = false;
                    
                        let items = [];
                        _new.data = data.results.productResult.products;
                        items = _new.data;
    
                        let center = {lat: -34.3420612, lng: 138.9141141};
                        let zoom = 9;
        
                        var map = new window.google.maps.Map(el.current, {
                            center: center,
                            zoom: zoom
                        });
        
                        items.forEach(_el => {
                            let geoCode = {
                                lat : Number(_el.additionalData.googlemap.geocodeGdaLatitude),
                                lng : Number(_el.additionalData.googlemap.geocodeGdaLongitude)
                            };
                            var marker = new window.google.maps.Marker({
                                position: {lat: geoCode.lat, lng: geoCode.lng},
                                map: map,
                                title:_el.productName
                                //icon : '/assets/icons/' + icon
                            });
                            var infowindow = new window.google.maps.InfoWindow({
                                //content : _el.shortDesc
                                content : '<div class="info__window">' +
                                            '<div class="info__title">' + _el.productName + '</div>' + 
                                            '<div class="info__town">' + _el.town + '</div>' + 
                                            '<div class="info__town">' + _el.address + ' ' +  _el.town + '</div>' + 
                                            '<p class="info__desc">' + _el.shortDesc + '</p>' + 
                                        '</div>'
                            });
                            
                            var isMobile = false;
                            //var isMobile = true;
                            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                                isMobile = true;
                            }
        
                            marker.addListener('mouseover', function(){
                                if(!isMobile) {
                                    infowindow.open(map, marker);
                                }
                            });
                            marker.addListener('mouseout', function(){ 
                                if(!isMobile) {
                                    infowindow.close();
                                }
                            });
                            marker.addListener('click', function(){
                                if(isMobile) {
                                    setPopupData({
                                        productName : _el.productName,
                                        address : _el.address,
                                        town : _el.town,
                                        desc : _el.shortDesc,
                                        slug : _el.slug
                                    });
                                    setOpenPopup(true);   
                                }
                                else{
                                    history.push('/atdwproduct/' + _el.slug);
                                }
                            });
                        });
                        return _new;
                    });
                }, 1000);
            }
            else{
                setMapSate(pre=>{
                    let _new = {...pre};
                    _new.fetchAble = false;
                
                    let items = [];
                    _new.data = data.results.productResult.products;
                    items = _new.data;
    
                    let center = {lat: -34.3420612, lng: 138.9141141};
                    let zoom = 9;
    
                    var map = new window.google.maps.Map(el.current, {
                        center: center,
                        zoom: zoom
                    });
    
                    items.forEach(_el => {
                        let geoCode = {
                            lat : Number(_el.additionalData.googlemap.geocodeGdaLatitude),
                            lng : Number(_el.additionalData.googlemap.geocodeGdaLongitude)
                        };
                        var marker = new window.google.maps.Marker({
                            position: {lat: geoCode.lat, lng: geoCode.lng},
                            map: map,
                            title:_el.productName
                            //icon : '/assets/icons/' + icon
                        });
                        var infowindow = new window.google.maps.InfoWindow({
                            //content : _el.shortDesc
                            content : '<div class="info__window">' +
                                        '<div class="info__title">' + _el.productName + '</div>' + 
                                        '<div class="info__town">' + _el.town + '</div>' + 
                                        '<div class="info__town">' + _el.address + ' ' +  _el.town + '</div>' + 
                                        '<p class="info__desc">' + _el.shortDesc + '</p>' + 
                                    '</div>'
                        });
                        
                        var isMobile = false;
                        //var isMobile = true;
                        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                            isMobile = true;
                        }
    
                        marker.addListener('mouseover', function(){
                            if(!isMobile) {
                                infowindow.open(map, marker);
                            }
                        });
                        marker.addListener('mouseout', function(){ 
                            if(!isMobile) {
                                infowindow.close();
                            }
                        });
                        marker.addListener('click', function(){
                            if(isMobile) {
                                setPopupData({
                                    productName : _el.productName,
                                    address : _el.address,
                                    town : _el.town,
                                    desc : _el.shortDesc,
                                    slug : _el.slug
                                });
                                setOpenPopup(true);   
                            }
                            else{
                                history.push('/atdwproduct/' + _el.slug);
                            }
                        });
                    });
                    return _new;
                });
            } 
        }
        fetchData();
        return function cleanup(){
            abortController.abort();
            if(!checkScript){
                document.body.removeChild(script);
            }
        }
    }, [mapSate, history]);

    const switchMap = useCallback((value)=>{
        setMapSate(pre=>{
            let _new = {...pre};
            _new.fetchAble = true;
            _new.currentFilterType = value;
            return _new;
        });
    }, []);

    const Filters = (() => {
        return(
            <div css={style.filters} className="atdw__map__filter">
                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'ACCOMM' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'ACCOMM'){
                        switchMap('ACCOMM');
                    }
                }}>ACCOMMODATION</button>
                
                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'ATTRACTION' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'ATTRACTION'){
                        switchMap('ATTRACTION');
                    }
                }}>ATTRACTIONS</button>
                
                {/* <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'DESTINFO' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'DESTINFO'){
                        switchMap('DESTINFO');
                    }
                }}>TOWN</button> */}
                
                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'RESTAURANT' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'RESTAURANT'){
                        switchMap('RESTAURANT');
                    }
                }}>RESTAURANTS</button>

                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'EVENT' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'EVENT'){
                        switchMap('EVENT');
                    }
                }}>EVENTS</button>

                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'HIRE' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'HIRE'){
                        switchMap('HIRE');
                    }
                }}>HIRE</button>

                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'INFO' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'INFO'){
                        switchMap('INFO');
                    }
                }}>INFO</button>

                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'TOUR' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'TOUR'){
                        switchMap('TOUR');
                    }
                }}>TOURS</button>

                <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'GENSERVICE' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'GENSERVICE'){
                        switchMap('GENSERVICE');
                    }
                }}>GENERAL</button>
                {/* <button css={style.button} className={`btn btn-primary ${mapSate.currentFilterType === 'JOURNEY' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterType !== 'JOURNEY'){
                        switchMap('JOURNEY');
                    }
                }}>JOURNEY</button> */}
            </div>
        )
    });
    
    return(
        <div css={style.atdwMap} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div style = {{height : "450px"}}>
                <div ref = {el} style = {{height:"100%"}}></div>
            </div>
            <Filters />
            <Portal>
                {
                    openPopup && 
                    <Fragment>
                        <div css={style.popupBackground}></div>
                        <div css={style.container}>
                            <div css={style.popupTitle}> { popupData.productName } </div>
                            <div css={style.popupTown}> { popupData.town } </div>
                            <div css={style.popupTown}> { popupData.address + ' ' +  popupData.town } </div>
                            <p css={style.popupDesc}> { popupData.desc } </p>
                            <div style={{textAlign:'center'}}>
                                    <button className="btn btn-primary" onClick={()=>{
                                        history.push('/atdwproduct/' + popupData.slug);
                                    }}>Go</button>
                                    <button className="btn btn-primary" style={{marginLeft:'1rem'}} onClick={()=>{
                                        setOpenPopup(false);
                                        setPopupData(null);
                                    }} >Close</button>
                            </div>
                        </div>
                    </Fragment>
                }
            </Portal>
        </div>
    )
}