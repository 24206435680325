//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    pageTiles : css({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         //margin: '2rem -10px'
         margin: '2rem auto',
         maxWidth : '960px',
         '&.notUseSlider .pageTile__j5' : css(mq({
               flexBasis: ['100%', '46%', '30.33%'],
               maxWidth : ['100%', '46%', '30.33%'],
               position: 'relative',
               margin: "10px",
               overflow:'hidden'
         }))
    }),
    useSliderpageTiles : css({ 
          '& .slick-track' : css({
               display:'flex !important',
               '& .slick-slide' : css({
                    height: 'inherit !important',
               }),
               '& .slick-slide>div' : css({
                    height:'100%'
               }),
               '& .pageTile__j5' : css({
                    height : '100%',
                    margin:'0px !important',
                    padding : '10px'
               }),
               '& a.pageTile__j5__content' : css({
                    display:'flex',
                    flexDirection:'column',
                    height: '100%',
                    '& .pageTile__j5__content__details' : css({
                         height: 'unset !important',
                         flexGrow : '1'
                    })
               })
          }), 
          '&.useSlider .pageTile__j5' : css({
               margin: "10px"
          }),
    }),
//     pageTile__tile : css(mq({
//          flexBasis: ['100%', '46%', '30.33%' ],
//          maxWidth : ['100%', '46%', '30.33%' ],
//          //padding: '10px',
//          position: 'relative',
//          margin: "10px",
//          overflow:'hidden'
//     })),

    tile__content : css({
         '&:hover' : {
            textDecoration: 'none',
            '.pageTile__j5__content__details' : {
                 opacity:'1 !important'
            }
         } 
    }),

    tile__bg : css(mq({
          transition: 'all .2s ease-in-out',
          height: ['auto !important', '200px !important'],
          objectFit: 'cover',
    })),

    tile__details : css({
          transition: 'all .2s ease-in-out',
          marginTop: '0.5rem',
          //padding: '.5rem 2rem 1.5rem',
          color: '#000',
          textDecoration: 'none',
          overflow: 'hidden',
          padding: '10px 15px',
          height:'100%'
     }),

    tile__details__wrapper : css({
          minHeight: '7rem'
    }),

    tile__details__title : css({
         //marginBottom : '1rem',
         color : '#fff',
         margin: '0px',
         fontSize : '1.4rem',
         fontWeight : '700',
         marginBottom: '0.5rem'
    }),
    tile__details__desc : css(mq({
          fontSize : ['0.9rem', '0.7rem'],
          fontWeight: '100',
          lineHeight: '1.2',
          color : '#fff'
    }))
}