import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default {
    tileSize : css({
        //border : '1px solid red',
        position : 'relative',
        height : '450px',
        'img' : {
            //border : '1px solid red'
            position : 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width : '101%'
        }
    }),
    banerIconsBar : css({
        textAlign : 'center',
        backgroundColor : 'grey',
        padding : '0.3rem 0',
        display : 'flex',
        flexDirection : 'row',
        flexWrap : 'wrap',
        justifyContent : 'center',
        'svg' : {
            width : '32px',
            height : '32px',
            color : 'white'
        },
        '.bar__item' : {
            margin : '0px 20px',
            cursor : 'pointer',
            '&:hover' : {
                textDecoration : 'unset'
            }
        },
        '.icon__text' : {
            fontSize : '.7rem',
            color : '#fff'
        }
    }),
    cityName : css({
        textAlign : 'center',
        margin : '0px'
    }),
    productName : css({
        textAlign : 'center',
        margin : '0px'
    }),
    detailContainer : css({
        '.Collapsible' : {
            //backgroundColor : 'grey'
        },
        '.Collapsible__contentInner' : {
            padding: '10px',
            //border: '1px solid red',
            borderTop: '0',
            fontSize : '14px',
            'p' : {
                marginBottom: '10px',
                fontSize: '14px',
                lineHeight: '20px',
                '&:last-child' : {
                    marginBottom: '0'
                }
            }
        },
        '.Collapsible__trigger' : {
            display: 'block',
            fontWeight: '400',
            textDecoration: 'none',
            position: 'relative',
            //border: '1px solid white',
            borderBottom: '1px solid #252525',
            padding: '10px',
            background: 'transparent',
            color: '#252525',
            cursor : 'pointer',
            '&:after' : {
                content: '""',
                position: 'absolute',
                right: '10px',
                top: '10px',
                display: 'block',
                transition: 'transform 300ms',
                backgroundImage : 'url("/assets/icons/arrow.svg")',
                padding : '15px',
                backgroundSize: 'contain',
                backgroundPosition: 'center'
            },
            '&.is-open' : {
                '&:after' : {
                    transform: 'rotateZ(180deg)'
                }
            },
            '&.is-disabled' : {
                opacity: '0.5',
                backgroundColor: 'grey'
            },
            'CustomTriggerCSS' : {
                backgroundColor: 'lightcoral',
                transition: 'background-color 200ms ease'
            },
            '.CustomTriggerCSS--open' : {
                backgroundColor: 'darkslateblue'
            },
            '.Collapsible__Custom-sibling' : {
                padding: '5px',
                fontSize: '12px',
                backgroundColor: '#CBB700',
                color: 'black'
            }
        }
    }),
    atdwLoaction__click : css({
        position : 'absolute',
        width : '100%',
        height:'48px',
        zIndex: '99999',
        cursor: 'pointer'
    })
}