import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default{
    atdwMap : css({
        maxWidth:'960px',
        margin: 'auto',
        '.info__window' : {
            maxWidth: '300px'
        },
        '.info__title' : {
            fontWeight: '700',
            fontSize: '15px'
        },
        '.info__town' : {
            fontWeight: '300',
            fontSize: '13px',
            margin:'0.2rem 0px'
        }
    }),
    filters : css({
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'center',
        marginTop: '0.5rem',
        marginBottom:'0.5rem',
        '.btn' : {
            lineHeight: '1',
            margin:'4px',
            fontSize:'0.6rem'
        },
        '.btn.selected' : {
            backgroundColor : '#89732a',
            borderColor: '#89732a'
        }
    }),
    popupBackground : css({
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9999'
    }),
    container : css({
        position: 'fixed',
        width: '95%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        marginTop: '-1rem',
        zIndex:'9999',
        backgroundColor:'white',
        padding: '1rem',
        maxWidth:'450px'
    }),
    popupTitle : css({
        fontWeight: '700',
        fontSize: '1rem'
    }),
    popupTown : css({
        fontWeight: '300',
        fontSize: '0.8rem',
        margin:'0.2rem 0px'
    }),
    popupDesc : css({
        fontSize:'0.8rem'
    }),
    button : css({
        fontWeight: '700'
    })
}