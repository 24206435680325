/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
//import { SiteLink } from 'components';
//import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import style from './style';
import { Link } from 'react-router-dom';
import { AtdwContext } from '../index';

export function AtdwTile(props){
    const product = props;
    const _atdwContext = useContext(AtdwContext);
    return(
        <div css={style.atdwTile} className="atdwTile" id={product.productId}>
            <Link to={'/atdwproduct/' + product.slug} onClick={()=>{
                _atdwContext.setupAtdwDataHistory2(product.productId);
            }}>
                <div css={style.bg} className="bg" style={{ backgroundImage: utils.css.bgUrlStyle(product.imageUrl) }}></div>
                <div css={style.town} className="town">
                    {product.town}
                </div>
                <div css={style.descContainer}  className="desc-container">
                    <div css={style.title} className="title">
                        {product.productName}
                    </div>
                    <hr />
                    <div css={style.desc} className="desc">
                        {
                            product.additionalData.PRODUCTTYPES.map((item, index)=> {
                                if(product.additionalData.PRODUCTTYPES.length === (index + 1)){
                                    return(
                                        <span key={index}>{item.productTypeDescription}</span>
                                    )
                                }
                                else{
                                    return (
                                        <span key={index}>{item.productTypeDescription} | </span>
                                    )
                                }
                            })
                        }
                    </div>
                    <div css={style.shotDesc} className="shot-desc">
                        {product.shortDesc}
                    </div>
                </div>
            </Link>
        </div>
    )
}