import React from 'react';
import utils from '../../utils';
import * as yt from './iframeApi';

export function YoutubeEmbed({ url, title }) {
  const videoId = yt.getYoutubeCode(url);
  //const posterImageUrl = yt.thumbnailMax(videoId);

  const playerVars = {
    'enablejsapi': 1,
    //'autoplay': 1,
    'controls': 1,
    'loop': 0,
    'showinfo': 0,
    'rel': 0,
    'modestbranding': 1,
    //'origin': typeof window !== `undefined` && window.location.origin,
    'allowfullscreen': true,
    'wmode': 'transparent'
  }

  return (
    <div className="youtubeEmbed ytvideo">
      <iframe className="youtubeEmbed__iframe" width="640" height="390"
        title={title || 'Youtube video'}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        src={`https://www.youtube.com/embed/${videoId}?${utils.url.toQueryString(playerVars)}`}
        frameBorder="0" allowFullScreen></iframe>
    </div>
  );
}
