import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles : css({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         margin: '2rem -10px',
         overflow:'hidden'
    }),
    pageTile__tile : css(mq({
         flex: '0 0 100%',
         maxWidth: '100%',
         padding: '10px',
         zIndex:'1',
         '&:nth-of-type(even)' : {
               '.pageTile__bsyc__bg' : mq({
                    order: ['0',null,'2'],
               }),
               '.pageTile__bsyc__content__details' : mq({
                    order: '1'
               })
         }
    })),

    tile__content : css({
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details' : {
               color: 'white',
            },
          //   '& .pageTile__bsyc__content__details::after' : {
          //       content:'""',
          //       backgroundColor:'rgba(0,0,0,0.5)',
          //       width:'100%',
          //       height:'100%',
          //       display:'block'
          //   },
            '& .pageTile__bsyc__bg2' : {
               //transform: scale(1.05)
               transform : 'scale(1.05)'
            }
         }
    }),

    tile__flip__wrapper : css({
          zIndex:'-1', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
          flexWrap: 'wrap'
    }),

tile__bg : css(mq({
     height: '0',
     paddingTop: ['50%','35%','30%'],
     //height: ['50%','35%','30%'],
     flex: '1 1 300px',
     overflow:'hidden',
     position:'relative'
})),

tile__bg2 : css(mq({
    height: '100%',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //overflow:'hidden',
    transition:'all .2s',
    position:'absolute',
    top:'0px',
    width:'100%'
})),
    tile__details : css({
         overflow: 'hidden',
         flexGrow: '1',
         textAlign: 'center',
         width: '100%',
         bottom: '0',
         backgroundImage : 'url("/assets/tile-graphic.png?v=1")',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'contain',
         backgroundColor: variables.themeDefaultColor,
         '&.left' : css({
               backgroundPosition: 'left'
         }),
         '&.right' : css({
               backgroundPosition: 'right'
         }),
         '&.default' : css({
               backgroundColor: variables.themeDefaultColor
         }),
         '&.red' : css({
            backgroundColor: variables.themeRedColor
          }),
          '&.orange' : css({
               backgroundColor: variables.themeOrangeColor
          }),
          '&.green' : css({
               backgroundColor: variables.themeGreenColor
          }),
          '&.blue' : css({
               backgroundColor: variables.themeBlueColor
          }),
          '&.yellow' : css({
               backgroundColor: variables.themeYellowColor
          }),
          '&.purple' : css({
               backgroundColor: variables.themePurpleColor
          }),
         '&.grey' : css({
               backgroundColor: variables.themeGreyColor
          }), 
         //backgroundColor : '#77cf22',
         flex: '1 1 300px',
         transition: 'all .2s ease-in-out',
         color: '#ebebeb',
         position:'relative',
         '& .pageTile__bsyc__container' : mq({
            //position: 'absolute',
            position: ['static,',null,'absolute'],
            top: '50%',
            left:'50%',
            transform: ['none',null,'translate(-50%, -50%)'],
            width:['100%',null,'90%'],
            padding:['1rem',null,'0px'],
            '& .pageTile__bsyc__title' : {
                fontFamily: variables.familyHeader,
                fontSize: '2rem',
                //textTransform: 'uppercase',
                fontWeight: '800',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:'inherit'
            },
            '& .pageTile__bsyc__desc' : {
                lineHeight: '1.3',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color:'inherit',
                fontWeight: 500
             }
         })
    })
}