import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
//import { YoutubeBannerMobilePopup } from '../../../lib/youtube/youtubeBanner--mobilePopup';
//import {BannerPanel} from '../bannerPanel/view';
//import { YoutubeBanner } from '../../../components/youtubeBanner/youtubeBanner';
import { YoutubeBannerMobilePopup } from 'lib/youtube/youtubeBanner--mobilePopup';

export function YoutubeBannerPanel2(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }

  //const enableYoutube = env.isProd;
  //const canPlayVideo = () => utils.css.screenWidth() >= 1200
  //const canPlayVideo = () => window.innerWidth >= 1200
  const canPlayVideo = () => utils.css.screenWidth() >= 1200

  const cssClass = utils.classNames('cms_item', 'youtubePanel', heightSet, item.cssClass || item.anchorName || '');

  //console.log(window.innerWidth, canPlayVideo())

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {/* {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>} */}
      <YoutubeBannerMobilePopup video={video} canPlayVideo={canPlayVideo} />
    </div>
  );

  // return (
  //   <div style={{overflow:'hidden'}}>
  //     <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
  //     {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>}
  //     {/* <YoutubeBanner video={video} />
  //     {false && <MobileSlider item={item}></MobileSlider>} */}
  //     </div>
  //   </div>
  // );
}