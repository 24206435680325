import React from 'react';
import { useRouter } from 'components';
import { gtm } from 'lib/tracking';

export function SearchBar({onCloseMenu}) {
  const [keyword, setKeyword ] = React.useState('')
  const { navigate } = useRouter();
  function search(e) {
    e.preventDefault();
    onCloseMenu();
    navigate('/search-results?q=' + encodeURIComponent(keyword));

    if(keyword && !keyword !==''){
      gtm.sendEvent('searchKeywordSubmitted', {searchKeyword: keyword} )
    }

    setKeyword("");
  }
  function onChange(e) {
    setKeyword(e.target.value);
  }
  return (
    <form className="searchBar" onSubmit={(e) => search(e)}>
      <input type="text" className="searchBar__input" placeholder="SEARCH" value={keyword} onChange={onChange}></input>
    </form>);
}