import React from 'react';
import { Item } from 'cms/items/item';

export function TestimonialPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  let itemElt = (<div>{item.itemTypeCode}</div>);
  
  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <div className="testimonial-panel">
      {itemElt}
      {subItemsElts}
    </div>
  );
}
