import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import Slider from "react-slick";

function getPages(pagesSerialized) {
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
}
  
function Tile(props) {
    const page = props.page;
    //console.log("aa", page);
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    const disableLink = props.disableLink;
    return (      
      <div className={`pageTile__j5 theme--${page.colorTheme}`}>
        <SiteLink className="pageTile__j5__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>          
         <img className="pageTile__j5__bg" css = {style.tile__bg} src = {imageUrl} alt = "image3"/>
        <div css = {style.tile__details} className="pageTile__j5__content__details">
            <div css={style.tile__details__wrapper} className="pageTile__j5__details__wrapper">
                <h3 css = {style.tile__details__title} className="pageTile__j5__title">
                    {page.pageTitle}
                </h3>
                <div css={style.tile__details__desc} className="pageTile__j5__desc">
                    {page.tileDesc || page.description}
                </div>
            </div>
        </div>
        </SiteLink>
      </div>
    )
}

export function PageTileJ5(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
    const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplaySpeed: 3000,
      fade: false,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const pageTileElts = pages.map((page, index) =>
        <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
    );
    const cssClass = utils.classNames('cms_item', 'pageTiles__j5', useSlider ? 'useSlider' : 'notUseSlider', item.cssClass || item.anchorName || '');
  
    if(useSlider){
      return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css = {style.useSliderpageTiles}>
          <Slider {...settings} >
            {pageTileElts}
          </Slider>
        </div>
      )
    }
    else{
      return (
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css = {style.pageTiles}>
          {pageTileElts}
        </div>
      );
    }
} 