import { useState } from 'react';

export function useHistory(currentPath) {
  const initHistory = currentPath ? [ historyItem(currentPath) ] : [];
  const [history, setHistory] = useState(initHistory);

  // useEffect(() => {
  //   console.log('use history')
  // });

  const push = (url) => {
//    console.log(url)
    const nav = historyItem(url);
    
    const same = history.length > 0 
      && (JSON.stringify(history[history.length - 1]) === JSON.stringify(nav))
  //    console.log(same, history)
    if (!same) {
      setHistory([...history, nav]);
    }
  }

  const getLast = () => {
    if (history.length === 0) return null;
    return history[history.length - 1];
  }

  const getPreviousPath = () => {
    //console.log(history)
    if (history.length < 2) return null;
    return history[history.length - 2].url;
  }

  const getPrevious = () => {
    if (history.length === 0) return null;
    history.pop(); // remove the current first
    const item = history.pop();
    return item;
  }

  return { history, push, getLast, getPrevious, getPreviousPath };
};

function historyItem(url) {
  return { url: url};
}