/** @jsx jsx */
import { jsx } from '@emotion/core';
//import style from './style';
import utils from 'utils';
import { AtdwSearchOption } from '../searchOption/view'
import { AtdwSearchResult } from '../searchResult/view'
import cmsUtils from 'cms/utils/cmsUtils';
import { useState, useEffect, useCallback, useContext } from 'react';
import env from 'env';
import { AtdwContext } from '../index';

function AtdwSearchTitle(props){
    const type = props.type;
    const title = {sTitle : '', title : ''};
    if(type === 'ACCOMM'){
        title.sTitle = "SEARCH & BOOK";
        title.title = "ACCOMMODATION";
    }
    else if(type === 'ATTRACTION'){
        title.sTitle = "SEARCH";
        title.title = "ATTRACTIONS";
    }
    else if(type === 'DESTINFO'){
        title.sTitle = "SEARCH";
        title.title = "TOWN";
    }
    else if(type === 'RESTAURANT'){
        title.sTitle = "SEARCH";
        title.title = "EATING OUT";
    }
    else if(type === 'EVENT'){
        title.sTitle = "SEARCH";
        title.title = "EVENT";
    }
    else if(type === 'INFO'){
        title.sTitle = "SEARCH";
        title.title = "INFO";
    }
    else if(type === 'TOUR'){
        title.sTitle = "SEARCH";
        title.title = "TOUR";
    }
    else if(type === 'GENSERVICE'){
        title.sTitle = "SEARCH";
        title.title = "GENSERVICE";
    }
    else if(type === 'JOURNEY'){
        title.sTitle = "SEARCH";
        title.title = "JOURNEY";
    }

    return(
        <h3 className="atdw__title"><strong>{title.sTitle}</strong> {title.title}</h3>
    )
}

export function AtdwSearch(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    //console.log("AtdwSearchItem11111", item);
    const cssClass = utils.classNames('cms_item', 'atdwSearch', item.cssClass || item.anchorName || '');
    const bgColor = cmsUtils.payload(item, 'BgColor');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
    const tilesSize = 12;
    const atdwType = cmsUtils.payload(item, 'ProductType');

    let bgUrl = null;
    if (imageUrl)
        bgUrl = utils.site.resourcePath(imageUrl);
    else if (backgroundTile)
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

    const [atdwState, setAtdwState] = useState({
        atdwType : atdwType,
        index : 1,
        atdwDataFromDB : [],
        reserveAtdwData : [],
        atdwData : [],
        towns : [],
        trigger : 0,
        context : useContext(AtdwContext)
    });

    //TXA
    const [trigger, setTrigger] = useState(0);
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [numOfAdults, setNumOfAdults] = useState('');
    const [numOfKids, setNumOfKids] = useState('');

    const onGetSearchOptionFun = ((value) => {
        const _checkIn = value.checkIn ? value.checkIn : '';
        const _checkOut = value.checkOut ? value.checkOut : '';
        const _numOfAdults = value.numOfAdults.value ? value.numOfAdults.value : '';
        const _numOfKids = value.numOfKids.value ? value.numOfKids.value : '';
        
        const subType = [];
        for(var i in value.subType){
            subType.push(value.subType[i].value);
        }
        const town = value.town.trim();
        const name = value.name.trim();

        const _filteredData = atdwState.atdwDataFromDB.filter((data) => { 
            let returnResult = false;
            //1
            if(subType.length > 0){
                for(var i in data.additionalData.PRODUCTTYPES){
                    returnResult = false;
                    if(subType.toString().includes(data.additionalData.PRODUCTTYPES[i].productTypeId)){
                        returnResult = true;
                    }
                    else{
                        returnResult = false;
                        break;
                    }
                }
            }
            else{
                returnResult = true;
            }

            //2
            if(returnResult === true && (town !== "" && town !== null)){
                returnResult = false;
                if(data.town.toLowerCase().includes(town.toLowerCase())){
                    returnResult = true;
                }
            }

            //3
            if(returnResult === true && (name !== "" && name !== null)){
                returnResult = false;
                if(data.productName.toLowerCase().includes(name.toLowerCase())){
                    returnResult = true;
                }
            }

            //4 for event //atdwState.atdwType === 'ACCOMM'
            if(atdwState.atdwType === 'EVENT'){
                if(returnResult === true && (_checkIn !== "" && _checkIn !== null)){
                    returnResult = false;
                    if(data.additionalData.eventFrequency.length > 0){
                        var fDate = new Date(_checkIn);
                        var tDate = new Date(_checkOut);
                        for(var n in data.additionalData.eventFrequency){
                            var pFDate = new Date(data.additionalData.eventFrequency[n].frequencyStartDate);
                            var pTDate = new Date(data.additionalData.eventFrequency[n].frequencyEndDate);
                            if(pFDate >= fDate && pTDate <= tDate){
                                returnResult = true;
                                break;
                            }
                        }
                    }
                    
                }
            }
            return returnResult;
        });
            
        const _data = [];
        if(_filteredData.length > 0){
            for(var x = 0; x < tilesSize; x++){
                _data.push(_filteredData[x]);
                if(x === (_filteredData.length -1)){
                    break;
                }
            }
        }

        //setReserveAtdwData(_filteredData);

        setAtdwState(pre => {
            let _new = {...pre};
            _new.reserveAtdwData = _filteredData;
            if(_checkIn !== '' && atdwState.atdwType === 'ACCOMM'){
                setCheckIn(_checkIn);
                setCheckOut(_checkOut);
                setNumOfAdults(_numOfAdults);
                setNumOfKids(_numOfKids);
                setTrigger(trigger + 1);
            }
            else{
                _new.atdwData = _data;
                _new.index = 1;
                
                _new.context.setupAtdwDataHistory({
                    atdwDataFromDB : _new.atdwDataFromDB,
                    reserveAtdwData : _new.reserveAtdwData,
                    atdwData : _new.atdwData,
                    index : _new.index
                });
                
            }
            return _new;
        });
    });

    useEffect(()=>{
        //console.log("useEffect_1");
        if(atdwState.context.atdwDataHistory2 !== null){
            setAtdwState(pre => {
                let _new = {...pre};
                _new.atdwDataFromDB = atdwState.context.atdwDataHistory.atdwDataFromDB;
                _new.reserveAtdwData = atdwState.context.atdwDataHistory.reserveAtdwData;
                _new.atdwData = atdwState.context.atdwDataHistory.atdwData;
                _new.index = atdwState.context.atdwDataHistory.index;
                return _new;
            });
            setTimeout(() => {
                document.getElementById(atdwState.context.atdwDataHistory2).scrollIntoView({behavior:'smooth', block:'center'});
                atdwState.context.setupAtdwDataHistory2(null);
            }, 500);
            return;
        }

        const fetchData = async () =>{
            const res = await fetch(env.apiBase + "/api/atdw/getproducts/" + atdwState.atdwType);
            const data = await res.json();
            const _data = [];
            if(data.products.length > 0){
                for(var i = 0; i < tilesSize; i++){
                    _data.push(data.products[i]);
                    if(i === (data.products.length -1)){
                        break;
                    }
                }
            }

            setAtdwState(pre => {
                let _new = {...pre};
                _new.atdwDataFromDB = data.products; 
                _new.towns = data.tows;
                _new.reserveAtdwData = data.products;
                _new.atdwData = _data;
                _new.context.setupAtdwDataHistory({
                    atdwDataFromDB : _new.atdwDataFromDB,
                    reserveAtdwData : _new.reserveAtdwData,
                    atdwData : _new.atdwData,
                    index : _new.index
                });
                return _new;
            });
        }
        fetchData();
    }, [ atdwState.atdwType, atdwState.context ]);

    useEffect(() => { 
        if(trigger === 0){
            return
        }
        const fetchData = async () => {
            const sendData = {
                checkIn : checkIn,
                checkOut : checkOut,
                adults : numOfAdults,
                kids : numOfKids,
                txaList : []
            };
            atdwState.reserveAtdwData.forEach(el => {
                if(el.additionalData.externalSystems.length > 0){
                    for(var i in el.additionalData.externalSystems){
                        if(el.additionalData.externalSystems[i].externalSystemCode === "TXA_DEFAULT"){
                            sendData.txaList.push(el.additionalData.externalSystems[i].externalSystemText);
                            break;
                        }
                    }
                }
            });

            const res = await fetch(env.apiBase + "/api/atdw/gettxaproducts", {
                method : 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(sendData)
            });
            const data = await res.json();
            //console.log("data", data);
            const r_data = [];
            const _data = [];
            for(var i = 0; i < atdwState.reserveAtdwData.length; i++){
                for(var n =0; i < atdwState.reserveAtdwData[i].additionalData.externalSystems; n++){
                    if(data.toString().includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){
                        r_data.push(atdwState.reserveAtdwData[i]);
                        if(i < tilesSize){
                            _data.push(atdwState.reserveAtdwData[i]);
                        }
                        break;
                    }
                }
            }
            //setReserveAtdwData(r_data);
            //setAtdwData(_data);
            setAtdwState(pre => {
                let _new = {...pre};
                _new.reserveAtdwData = r_data;
                _new.atdwData = _data;
                return _new;
            });
        };
        fetchData();
        setTrigger(0);
    }, [trigger, checkIn, checkOut, numOfAdults, numOfKids, atdwState.reserveAtdwData]);

    const addAtdw = useCallback((reserveAtdwData, _atdwContext)=>{
        const av = [];
        for(var i = (atdwState.index * tilesSize); i < ((atdwState.index * tilesSize) + tilesSize); i++){
            av.push(reserveAtdwData[i]);
            if(reserveAtdwData.length - 1 === i){
                break;
            }
        }
        setAtdwState(pre => {
            let _new = {...pre};
            _new.atdwData = [..._new.atdwData, ...av];
            _new.index = _new.index + 1;
            _atdwContext.setupAtdwDataHistory({
                atdwDataFromDB :  _new.atdwDataFromDB,
                reserveAtdwData : _new.reserveAtdwData,
                atdwData : _new.atdwData,
                index : _new.index 
            }); 

            return _new;
        });
    }, [atdwState.index]);

    return(
        <section style={{backgroundColor: `${bgColor}`, backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl), padding: '1rem 0px'}} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className = "container">
                <AtdwSearchTitle type = {atdwState.atdwType}/>
                <AtdwSearchOption atdwType = {atdwState.atdwType} towns = {atdwState.towns} onGetSearchOption = {onGetSearchOptionFun} />
                <div style={{marginLeft:'1rem'}}>
                    1 to { atdwState.atdwData.length } of { atdwState.reserveAtdwData.length }
                </div>
                { atdwState.atdwData ? (
                    <AtdwSearchResult atdwData = {atdwState.atdwData} />
                ) : null }
                { atdwState.reserveAtdwData.length >=12 ? (
                    <div style={{marginRight:"1rem", textAlign:'right'}}>
                        1 to { atdwState.atdwData.length } of {atdwState.reserveAtdwData.length}
                    </div>
                ) : null }

                {
                    atdwState.atdwData.length !== atdwState.reserveAtdwData.length && atdwState.atdwData.length > 0 ?
                        <div style={{textAlign:'center'}}>
                            <button className="btn btn-primary" type="button" onClick = {()=>{
                                addAtdw(atdwState.reserveAtdwData, atdwState.context);
                            }}>MORE</button>
                        </div> : null
                }

                {
                    atdwState.atdwData.length === 0 ?
                    <div style={{textAlign:'center', margin:'1rem 0px'}}>Sorry, there are no available results.</div> : null
                }
                
            </div>
        </section>
    );
}