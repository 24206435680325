/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import env from 'env';
import { useRouter } from 'components';
import Slider from "react-slick";
import style from './style';
import { MdDirections, MdCall, MdWeb, MdBook } from "react-icons/md";
//import { SiteLink } from 'components';
import Collapsible from 'react-collapsible';
import { ViewPartial } from 'cms/pages/partials/viewPartial';
import { MetaTag } from 'components';
import utils from 'utils';
import { gtm } from 'lib/tracking';
import { AtdwPagePartial } from '../../../cms/pages/partials/atdwPagePartial';

function AtdwPageBaner(props){
    const multimedia = props.multimedia;
    //console.log("aa", multimedia);
    const communication = props.communication;
    const _com = {phone : '', website : '', book : ''};
    
    communication.forEach(el => {
        if(el.attributeIdCommunicationDescription === 'Primary Phone'){
            _com.phone = el.communicationDetail;
        }
        else if(el.attributeIdCommunicationDescription === 'URL Enquiries'){
            _com.website = el.communicationDetail.includes("http") ? el.communicationDetail : 'https://' + el.communicationDetail;
        }
        else if(el.attributeIdCommunicationDescription === 'Booking URL'){
            _com.book = el.communicationDetail.includes("http") ? el.communicationDetail : 'https://' + el.communicationDetail;
        }
    });

    let va = [];
    const abc = [];
    let checkImageType = false;

    // for(var i = 0; i < multimedia.length; i++){
    //     if(i === 0){
    //         va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} />));
    //     }
    //     else{
    //         if(multimedia[i-1].multimediaId === multimedia[i].multimediaId){
    //             va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} />));
    //         }
    //         else{
    //             checkImageType = true;
    //             let _images = va;
    //             va = [];
    //             abc.push(( 
    //                 <div css = {style.tileSize} className="tileSize" key = {multimedia[i].multimediaId}>
    //                     <picture>
    //                         {_images}
    //                         <img src ="/assets/logos/logo2.png"  alt="image2"/>
    //                     </picture>
    //                 </div>));
    //             va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} />));
    //         }

    //         if(i === (multimedia.length - 1) && checkImageType === false){
    //             let _images = va;
    //             va = [];
    //             abc.push(( 
    //                 <div css = {style.tileSize} className="tileSize" key = {multimedia[i].multimediaId}>
    //                     <picture>
    //                         {_images}
    //                         <img src ="/assets/logos/logo2.png"  alt="image2"/>
    //                     </picture>
    //                 </div>));
    //             va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} />));
    //         }
    //     }
    // }

    for(var i = 0; i < multimedia.length; i++){

        if(i === 0 ){
            if(multimedia[i].attributeIdMultimediaContent === 'IMAGE' &&
                multimedia[i].attributeIdSizeOrientation === '4X3' &&
                parseInt(multimedia[i].width) >= 800){
                va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} media={ multimedia[i].width <= 800 ?'(min-width:600px)' : null } />));
            }
        }
        else{
            if(multimedia[i-1].multimediaId === multimedia[i].multimediaId){
                if(multimedia[i].attributeIdMultimediaContent === 'IMAGE' &&
                    multimedia[i].attributeIdSizeOrientation === '4X3' &&
                    parseInt(multimedia[i].width) >= 800){
                    va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} media={ multimedia[i].width <= 800 ?'(min-width:600px)' : null } />));
                }   
            }
            else{
                checkImageType = true;
                let _images = va;
                va = [];
                abc.push(
                    <div css = {style.tileSize} className="tileSize" key = {multimedia[i].multimediaId}>
                        <picture>
                            {_images}
                            <img src ="/assets/logos/logo2.png"  alt="image2"/>
                        </picture>
                    </div>);

                if(multimedia[i].attributeIdMultimediaContent === 'IMAGE' &&
                    multimedia[i].attributeIdSizeOrientation === '4X3' &&
                    parseInt(multimedia[i].width) >= 800){
                    va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} media={ multimedia[i].width <= 800 ?'(min-width:600px)' : null } />));
                }
            }

            if(i === (multimedia.length - 1) && checkImageType === false){
                let _images = va;
                va = [];
                abc.push(( 
                    <div css = {style.tileSize} className="tileSize" key = {multimedia[i].multimediaId}>
                        <picture>
                            {_images}
                            <img src ="/assets/logos/logo2.png"  alt="image2"/>
                        </picture>
                    </div>));
                if(multimedia[i].attributeIdMultimediaContent === 'IMAGE' &&
                    multimedia[i].attributeIdSizeOrientation === '4X3' &&
                    parseInt(multimedia[i].width) >= 800){
                    va.push((<source key = {i} crossOrigin="anonymous" srcSet={multimedia[i].serverPath} media={ multimedia[i].width <= 800 ?'(min-width:600px)' : null } />));
                }
            }
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // const goOpenLocation = ((e) => {
        
    // });

    return(
        <section>
            <Slider {...settings}>
                { abc }
            </Slider>
            <div css={style.banerIconsBar} className="atdw__page__banerIconsBar">
                {
                    _com.phone === '' ? null : (
                        <a href={'tel:' + _com.phone} className="bar__item">
                            <MdCall />
                            <div className="icon__text">PHONE</div>
                        </a>
                    )
                }

                <div className="bar__item" onClick={()=>{
                    props.onCheckOpen();
                    document.getElementById('atdwPageLocation').scrollIntoView({behavior:'smooth', block:'center'});
                }}>
                    <MdDirections/>
                    <div className="icon__text">LOCATION</div>
                </div>

                {
                    _com.website === '' ? null : (
                        <a href={_com.website} className="bar__item" target="_blank" rel="noopener noreferrer">
                            <MdWeb />
                            <div className="icon__text">WEBSITE</div>
                        </a>
                    )
                }

                {
                    _com.book === '' ? null : (
                        <a href={_com.book} className="bar__item" target="_blank" rel="noopener noreferrer">
                            <MdBook />
                            <div className="icon__text">BOOKINGS</div>
                        </a>
                    )
                }
            </div>
        </section>
    )
}

function AtdwContactDetails(props){

    const communication = props.communication;
    //const entity_fac = [];
    return (
        <Collapsible trigger="Contact Details">
            {
                communication.map((item, index) => {
                  return(
                      <p key={index}>
                          {item.attributeIdCommunicationDescription + ' : ' + item.communicationDetail}
                      </p>
                  )
                })
            }
        </Collapsible>
    )
}

function AtdwDateTime(props){
    const dates = props.dates;
    const todayDate = new Date();
    return (
        
        dates.length > 0 ? (
            <Collapsible trigger="Date &amp; Time">
            <div className="row">
                {
                    dates.map((item, index) => {
                        var pDate = new Date(item.frequencyStartDate);
                        if( pDate >= todayDate){
                            return(
                                <div className="col-6 col-sm-4" key={index} style={{fontFamily:'sans-serif'}}>
                                    { 
                                        ((''+ pDate.getDate()).length < 2 ? '0' + pDate.getDate() : ''+pDate.getDate()) + '-' +
                                            ((''+(pDate.getMonth() + 1)).length < 2 ? '0' + (pDate.getMonth() + 1) : ''+(pDate.getMonth() + 1)) + '-' +
                                            pDate.getFullYear() + ' : ' +
                                            item.frequencyStartTime
                                    }
                                </div>
                            )
                        }
                        return null;
                    })
                }
            </div>
        </Collapsible>
        ):null
    )
}

function AtdwAttributes(props){
    const attributes = props.attributes;
    const entity_fac = [];
    for(var i in attributes){
        if(attributes[i].attributeTypeId === "ENTITY FAC"){
            entity_fac.push(
                <div className="col-6 col-sm-4" key={i}>
                    {attributes[i].attributeIdDescription}
                </div>
            )
        }
    }

    return( 
        <Collapsible trigger="Features">
            <div className="row">
                {entity_fac}
            </div>
        </Collapsible> 
    )
}

function AtdwLocation(props){
    const addresses = props.addresses;
    const productName = props.productName;
    const geoCode = { lat : 0, lng : 0};
    const ele = useRef(null);
    //const [open, setOpen] = useState(false);

    for(var i in addresses){
        if(addresses[i].attributeIdAddress === "PHYSICAL"){
            geoCode.lat = Number(addresses[i].geocodeGdaLatitude);
            geoCode.lng = Number(addresses[i].geocodeGdaLongitude);
            break;
        }
    }

    useEffect(() => {
        
        let checkScript = true;
        const script = document.createElement('script');
        if(window['google'] === undefined){
            checkScript = false;
        }
        else{
            if(window['google'].maps === undefined){
                checkScript = false;
            }
        }

        if(!checkScript){    
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
            script.async = true;
            document.body.appendChild(script);
        }

        return () =>{
            if(!checkScript){
                document.body.removeChild(script);
            }
        }

    }, [productName, geoCode]);

    return(
        <Fragment>
        <div css={style.atdwLoaction__click} onClick={()=>{
            props.onCheckOpen();
            var map, marker;
            map = new window.google.maps.Map(ele.current, {
                center: {lat: geoCode.lat, lng: geoCode.lng},
                zoom: 15
            });
            console.log(marker);
            marker = new window.google.maps.Marker({
                position: {lat: geoCode.lat, lng: geoCode.lng},
                map: map,
                title:productName
            });
        }}></div>
        <Collapsible trigger="Location" open={props.checkOpen}>
            <div style = {{height : "450px"}}>
                <div ref = {ele} style = {{height:"100%"}}></div>
            </div>
        </Collapsible>
        </Fragment>
    )
}

function getMetaTag(product, slug) {
    gtm.pageView('/atdwproduct/' + slug);
    return {
      title: product.productName,
      description: product.productShortDescription,
      keywords: '',
      heroImageUrl: product.multimedia[0].serverPath,
      language: 'English',
      country: 'Australia',
      siteName: 'Light Country',
      url: utils.site.resourcePath('/atdwproduct/' + slug)
      //canonical: utils.site.resourcePath(pageData.pageUrl)
    }
}

export function AtdwPage(props){
    //const AtdwPageContext = React.createContext(null);
    const slug = useRouter().query.slug;

    const [atdwProduct, setAtdwProduct] = useState({
        product : null,
        themeColor : props.onChangeThemeColor
    });

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 1500,
    //     autoplaySpeed: 3000,
    //     fade: true,
    //     autoplay: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };

    useEffect(() => { 
        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/atdw/getproduct/" + slug);
            const data = await res.json();
            //setProduct(data.product);
            let themeColor = '';
            if(data.product.productCategoryId === 'ACCOMM'){
                themeColor = 'yellow';
            }
            else if(data.product.productCategoryId === 'RESTAURANT'){
                themeColor = 'orange';
            }
            else if(data.product.productCategoryId === 'ATTRACTION'){
                themeColor = 'blue';
            }
            else if(data.product.productCategoryId === 'EVENT'){
                themeColor = 'green';
            }
            else if(data.product.productCategoryId === 'HIRE'){
                themeColor = 'red';
            }
            else if(data.product.productCategoryId === 'GENSERVICE'){
                themeColor = 'lightgreen';
            }
            else if(data.product.productCategoryId === 'TOUR'){
                themeColor = 'lightblue';
            }

            setAtdwProduct(pre => {
                let _new = {...pre};
                _new.product = data.product;
                _new.themeColor('theme--' + themeColor);
                return _new;
            });
        };
        fetchData();
    }, [slug]);

    function GetDes(prop){
        let _texts = prop.text.split("\n");
        return (
            _texts.map((text, index) => <p key={index}>{text}</p>)
        )
    }
    const [checkOpen, setCheckOpen] = useState(false);

    const onCheckOpen = useCallback(()=>{
        setCheckOpen(!checkOpen);
    }, [checkOpen]);

    return( 
        <Fragment>
            {
                atdwProduct.product ?     
                <div className="atdw-page">
                <MetaTag data={getMetaTag(atdwProduct.product, slug)} />
                <AtdwPageBaner multimedia = {atdwProduct.product.multimedia} address = {atdwProduct.product.addresses} communication = {atdwProduct.product.communication} onCheckOpen = {onCheckOpen} />
                <section className="contentPanel">
                    <div className="container">
                        <div className="htmlPanel">
                            <h3 css={style.cityName} className="cityName"> { atdwProduct.product.cityName} </h3>
                            <h1 css={style.productName} className="productName"> { atdwProduct.product.productName} </h1>
                            <hr />
                            <div className="productDes">
                                <GetDes text = {atdwProduct.product.productDescription}></GetDes>
                            </div>
                            <div css={style.detailContainer} className="detail__container">
                                <AtdwContactDetails communication = {atdwProduct.product.communication}/>
                                <AtdwAttributes attributes = {atdwProduct.product.attributes}/>
                                <div id="atdwPageLocation" style={{position:'relative'}}>
                                    <AtdwLocation addresses = {atdwProduct.product.addresses} productName = {atdwProduct.product.productName}  checkOpen = {checkOpen} onCheckOpen = {onCheckOpen} />
                                </div>
                                <AtdwDateTime dates = {atdwProduct.product.eventFrequency}/>
                            </div>
                        </div> 
                    </div>
                </section>
            </div> : null
            }
            <AtdwPagePartial></AtdwPagePartial>
            <ViewPartial />
        </Fragment>
    )
}

//Sample
// const abc = multimedia.map((item, index) => {
    //     if(index === 0){
    //         va.push((<source key = {index} crossOrigin="anonymous" srcSet={item.serverPath} />));
    //     }
    //     else{
    //         if(multimedia[index-1].multimediaId === item.multimediaId){
    //             va.push((<source key = {index} crossOrigin="anonymous" srcSet={item.serverPath} />));
    //         }
    //         else{
    //             let _images = va;
    //             va = [];
    //             //setImages([])
    //             return(                
    //                 <div className="tileSize" key = {item.multimediaId}>
    //                     <picture>
    //                         {_images}
    //                         <img src ="/assets/logos/logo.png" />
    //                     </picture>
    //                 </div>
    //             ) 
    //         }
    //     }
    // });