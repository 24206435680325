/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useState, useEffect } from 'react';
import style from './style';
import { AtdwTile } from '../tile/view';
import { useEffect, useState, Fragment } from 'react';
import { MdArrowDropDownCircle } from "react-icons/md";

export function AtdwSearchResultWithSite(props){

    const atdwDatas = props.atdwData || [];
    
    const [atdw, setAtdw] = useState();
    const [open, setOpen] = useState({
        accom : false, attraction : false,
        destinfo : false, restaurant : false,
        event :false, info : false, hire : false,
        genservice:false, tour:false
    });

    useEffect(()=>{
        const _atdw = {
            accomm : [],
            attraction : [],
            destinfo : [],
            restaurant : [],
            event :[],
            info:[],
            hire :[],
            genservice :[],
            tour:[]
        };
        atdwDatas.forEach(el => {
            switch(el.productType){
                case 'ACCOMM' :
                    _atdw.accomm.push(el);
                    break;
                case 'ATTRACTION' :
                    _atdw.attraction.push(el);
                    break;
                case 'DESTINFO' :
                    _atdw.destinfo.push(el);
                    break;
                case 'RESTAURANT' :
                    _atdw.restaurant.push(el);
                    break;
                case 'EVENT' :
                    _atdw.event.push(el);
                    break;
                case 'INFO' :
                    _atdw.info.push(el);
                    break;
                case 'HIRE' :
                    _atdw.hire.push(el);
                    break;
                case 'GENSERVICE' :
                    _atdw.genservice.push(el);
                    break;
                case 'TOUR' :
                    _atdw.tour.push(el);
                    break;
                default:
            }
        });
        setAtdw(_atdw);
    }, [atdwDatas]);

    return(
        <div className="atdwResult atdwSearch">
           {
               atdw &&
               <Fragment>
                {
                    atdw.accomm.length > 0 &&
                    <div css={style.expendablePanel} className={open.accom ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.accom){
                            //setOpen(false);
                            setOpen({...open, accom : false});
                        }
                        else{
                            setOpen({...open, accom : true});
                        }
                    }}>
                        <span> {'ACCOMMODATION (' + atdw.accomm.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.accomm.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.attraction.length > 0 &&
                    <div css={style.expendablePanel} className={open.attraction ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.attraction){
                            //setOpen(false);
                            setOpen({...open, attraction : false});
                        }
                        else{
                            setOpen({...open, attraction : true});
                        }
                    }}>
                        <span> {'ATTRACTIONS (' + atdw.attraction.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.attraction.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.destinfo.length > 0 &&
                    <div css={style.expendablePanel} className={open.destinfo ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.destinfo){
                            //setOpen(false);
                            setOpen({...open, destinfo : false});
                        }
                        else{
                            setOpen({...open, destinfo : true});
                        }
                    }}>
                        <span> {'TOWN (' + atdw.destinfo.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.destinfo.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.restaurant.length > 0 &&
                    <div css={style.expendablePanel} className={open.restaurant ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.restaurant){
                            //setOpen(false);
                            setOpen({...open, restaurant : false});
                        }
                        else{
                            setOpen({...open, restaurant : true});
                        }
                    }}>
                        <span> {'EATING OUT (' + atdw.restaurant.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.restaurant.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.event.length > 0 &&
                    <div css={style.expendablePanel} className={open.event ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.event){
                            //setOpen(false);
                            setOpen({...open, event : false});
                        }
                        else{
                            setOpen({...open, event : true});
                        }
                    }}>
                        <span> {'EVENT (' + atdw.event.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.event.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.hire.length > 0 &&
                    <div css={style.expendablePanel} className={open.hire ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.hire){
                            //setOpen(false);
                            setOpen({...open, hire : false});
                        }
                        else{
                            setOpen({...open, hire : true});
                        }
                    }}>
                        <span> {'HIRE (' + atdw.hire.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.hire.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.info.length > 0 &&
                    <div css={style.expendablePanel} className={open.info ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.info){
                            //setOpen(false);
                            setOpen({...open, info : false});
                        }
                        else{
                            setOpen({...open, info : true});
                        }
                    }}>
                        <span> {'INFO (' + atdw.info.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.info.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.tour.length > 0 &&
                    <div css={style.expendablePanel} className={open.tour ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.tour){
                            //setOpen(false);
                            setOpen({...open, tour : false});
                        }
                        else{
                            setOpen({...open, tour : true});
                        }
                    }}>
                        <span> {'TOUR (' + atdw.tour.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.tour.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                {
                    atdw.genservice.length > 0 &&
                    <div css={style.expendablePanel} className={open.genservice ? 'open' : 'close'}>
                    <div css={style.expendablePanelTitle} className="exPanelTitle" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(open.genservice){
                            //setOpen(false);
                            setOpen({...open, genservice : false});
                        }
                        else{
                            setOpen({...open, genservice : true});
                        }
                    }}>
                        <span> {'GENERAL SERVICE (' + atdw.genservice.length +')'}</span>
                        <MdArrowDropDownCircle />
                    </div>
                    <div css={style.expendablePanelContent} className = "expendablePanelContent">
                        <div css={style.atdwTiles} className="atdwTiles"> 
                        { atdw.genservice.map((item, index) =>
                            <AtdwTile {...item} key = {index}></AtdwTile>
                        )}
                        </div>
                    </div>
                    </div>
                }
                
               </Fragment>
           } 
        </div>
    )
}