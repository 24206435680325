import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default {
    atdwTiles : css({
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        //justifyContent: 'space-between'
        justifyContent: 'start',
        marginTop: '0.2rem'
    }),
    expendablePanel : css({
        marginTop: '1rem',
        '&.open' : {
            '.exPanelTitle svg' : {
                transform:'translateY(-50%) rotate(180deg)'
            },
            '.expendablePanelContent' : {
                opacity: '1',
                //height: '100%'
                height: 'auto'
            }
        }
    }),
    expendablePanelTitle : css({
        borderBottom: '1px solid black',
        cursor:'pointer',
        position:'relative',
        'svg' : {
            position:'absolute',
            right:'0px',
            top:'50%',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s ease-in-out'
        }
    }),
    expendablePanelContent : css({
        transition: 'opacity 1s ease-out !important',
        opacity: '0',
        height: '0',
        overflow:'hidden'
    })

}