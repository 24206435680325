import utils from "utils";
import env from 'env';

export const isMobile = () => {
  var width = utils.css.screenWidth()
  return width < 768
}

export function resourcePath(url){
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export const isNZ = env.locale === 'nz'