const config = {
  isProd: process.env.NODE_ENV === "production",
  locale: getLocale(),
  siteName: process.env.REACT_APP_SITENAME,
  titleSuffix: process.env.REACT_APP_TITLESUFFIX,
  apiBase: process.env.REACT_APP_API_ENDPOINT,
  resourceBase: process.env.REACT_APP_RESOURCE_BASEURL,
  cmsEndpoint: process.env.REACT_APP_CMS_ENDPOINT,
  ver: process.env.REACT_APP_VERSION,
  updateDate: process.env.REACT_APP_UPDATED_AT,
  
  bvClientId: process.env.REACT_APP_BAZZAR_CLIENT_ID,
  bvSiteId: process.env.REACT_APP_BAZZAR_SITE_ID,
  bvEnv: process.env.REACT_APP_BAZZAR_ENV,
  bvLocale: getLocale() === 'nz' ? 'en_NZ' : 'en_AU',
}

function getLocale() {
  return process.env.REACT_APP_COUNTRY_LOCALE || getCountry()
}

function getCountry() {
  if (typeof window !== `undefined`) {
    if (window.location.hostname.toLowerCase().indexOf(".nz") >= 0) return 'nz';
    if (window.location.hostname.toLowerCase().indexOf("-nz") >= 0) return 'nz';
    if (window.location.search === '?nz=true') return 'nz';
  }
  return 'au';
}

console.log(process.env, config)
export default {
  ...config
}
