//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
//import { mq } from 'cssInJs'


export default {
    
    photoGallery : css({
        maxWidth : '960px',
        margin: 'auto'
    }),
    
    photos : css({
        marginLeft: '-5px',
        marginRight: '-5px',
        '& > div' : {
            display: 'flex',
            flexWrap: 'wrap'
        }
        // display: 'flex',
        // flexWrap: 'wrap',
        // marginLeft: '-5px',
        // marginRight: '-5px',
        // '& img' : {
        //     flex: '1 1 150px',
        //     margin: '5px',
        //     paddingTop : '60%',
        //     width : '150px',
        //     border: '1px solid red'
        // }
    }),
    photoContainer : css({
        color : 'red',
        flex: '1 1 150px',
        margin : '5px',
        height : '150px',
        display: 'flex',
        overflow: 'hidden'
    }),
    photo : css({
        margin : 'auto',
        transform: 'scale(3)'
    }),
    photoEmpty : css({
        flex: '1 1 150px',
        height : '0px'
    })
}