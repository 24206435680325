import React from 'react';
import env from 'env';
import { SiteLink} from 'components';
import { usePost } from 'components';
import { ErrorPopup, Loading } from 'components';
import { useRouter } from 'components';
import { AtdwSearchResultWithSite } from '../atdw/index';

export function SearchResult(props) {
  
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;
  //console.log(keyword);

  React.useEffect(() => {
    post.send(env.apiBase + '/api/search/search', { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }
  //console.log("post", post)
  const data = post.response;
  //const products = data.products;
  const pages = data.pages;
  const atdw = data.atdw;

  //console.log('data', data);

  // if (pages.length === 0) return (
  //   <div className="alert alert-danger" role="alert">
  //     No result found.
  //   </div>
  // )
  return (
    <>
      {pages.length > 0 ? <div>
      <h2 style={{marginTop: '2rem'}}>Pages</h2>
      <PageList pages={pages} />
      </div> : (!atdw.length > 0 &&
        <div className="alert alert-danger" role="alert">
          No result found.
        </div>) 
      }
      {
        atdw.length > 0 ?
        <div>
          <h2 style={{marginTop: '2rem'}}>ATDW</h2>
          <AtdwSearchResultWithSite atdwData = {atdw} ></AtdwSearchResultWithSite>
        </div> : (!pages.length > 0 &&
        <div className="alert alert-danger" role="alert">
            No ATDW result found.
        </div>)
      }
    </>
  );
}

// function ProductList({products}) {
//   return (
//     <div className="productList">
//       {products.map((product, index) =>
//         <Tile product={product} key={product.productCode}></Tile>
//       )}
//     </div>
//   );
// }

function PageList({pages}) {
  return (
    <div className="pageList row">
      {pages.map((page, index) =>
        <div className="pageListItem col-md-6 col-lg-4" key={index}>
          <div className="pageListItem__inner">
            <SiteLink className="pageListItem__title" to={page.pageUrl}>{page.pageTitle}</SiteLink>
            <div className="pageListItem__highlight" dangerouslySetInnerHTML={{ __html: page.searchHighlight }}></div>
          </div>
        </div>
      )}
    </div>
  );
}